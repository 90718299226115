@import url('react-pdf/dist/esm/Page/AnnotationLayer.css');
@import url('fonts/MyFontsWebfontsKit.css');
body {
  margin: 0;
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Gilroy', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.RaLayout-appFrame {
  margin-top: 48px !important;
}

.data-grid-container {
  &.saving .rdg-light {
    pointer-events: none;
    opacity: 50%;
  }
}
.rdg-text-right, .rdg-text-right input {
  text-align: right;
}