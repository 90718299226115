.react-datepicker {
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
}

.react-datepicker-popper {
  z-index: 3;
}

.react-datepicker__header {
  background-color: #fafafb;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: #383848;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #181822;
}

.react-datepicker__month-select, .react-datepicker__year-select {
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  padding: 5px;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__input-container {
  display: flex;
}

.react-datepicker__input-container > input {
  font-size: 16px;
  font-family: inherit;
  letter-spacing: inherit;
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  display: block;
  min-width: 0;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 8.5px 14px;
  border-radius: 4px;
  flex-grow: 1;
}

.invalid-date-range .react-datepicker__input-container > input:not(.react-datepicker-ignore-onclickoutside) {
  color: #d32f2f;
  border-color: #d32f2f;
}

.react-datepicker__input-container > input:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.react-datepicker__time-container {
  width: 82px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: auto;
}